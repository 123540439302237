import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import { actions as AppActions, types as AppTypes, selectAlternateIdEnabled, selectLocationAlternateIdEnabled } from '../../../reducers/app';

import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../../components/ReactTableLoadingComponent';
import moment from 'moment';

import { actions as CallHistoryActions, makeSelectCallHistoryPageCount, makeSelectCallHistoryList, makeSelectCallHistoryListLoading } from '../../../reducers/homepage';
import { selectUserCompany, currentCompanyLevel } from '../../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import NumberFormatter from '../../../components/NumberFormatter/NumberFormatter';
import './callHistorySection.css';

function formatLastAccess(callTime) {
    return callTime ?  moment(callTime).format('MM/DD/YYYY hh:mm:ss A') : '';
} 

function PhoneNumberAltId(props) {
    const { row } = props;
    if(row.original.phoneNumber) {
        return (
            <div title={row.original.phoneNumber}>{!isNaN(row.original.phoneNumber) ? <NumberFormatter number={row.original.phoneNumber} /> : row.original.phoneNumber}</div>   
        );
    }
    else if(row.original.locationAlternateId) {
        return <div title={row.original.locationAlternateId}>{row.original.locationAlternateId}</div>;
    }
    else if(row.original.deviceUserId) {
        return <div title={row.original.deviceUserId}>{row.original.deviceUserId}</div>;
    }
    return '';
}

// CIR-5195
// System LEVEL -> Column Title:Phone Number/ID | Value: Display either phone number/alternate ID/device user ID
// Org LEVEL -> Column Title: if no features (ALT_ID|DEVICE_USER_ID) Phone Number | Value:  Display either phone number
// ORG LEVEL -> Column Title: if either (ALT_ID||DEVICE_USER_ID) Phone Number / ID | Value: Display either phone number/alternate ID/device user ID
function buildTableColumns(currentCompanyLevel, deviceAlternateIdEnabled, locationAlternateIdEnabled) {
    let tableColumns = [];
    tableColumns.push({
        id: 'calltime',
        Header: <div id="recent-emergency-calls-time">Time</div>,
        sortable: false,
        Cell: row => <div title={formatLastAccess(row.original.callTime)}>{formatLastAccess(row.original.callTime)}</div>
    });
    tableColumns.push({
        Header: <div id="recent-emergency-calls-phone-number">
        {
            currentCompanyLevel === AppTypes.CURRENT_COMPANY_LEVEL_SYSTEM || deviceAlternateIdEnabled || locationAlternateIdEnabled ? 
            'Phone Number / ID' : 
            'Phone Number' 
        }
        </div>,
        id: 'elin',
        Cell: row => <PhoneNumberAltId row={row} />
    });
    tableColumns.push({
        Header: <div id="recent-emergency-calls-location">Location</div>,
        id: 'location',
        Cell: row => <div title={row.original.address && `${row.original.address}, ${row.original.city}, ${row.original.state} ${row.original.zip}`}>{row.original.address && `${row.original.address}, ${row.original.city}, ${row.original.state} ${row.original.zip}`}</div>
    });
    return tableColumns;
}

function CallHistorySection(props) {
    const {
        loading, callHistory, fetchCallHistoryList, callHistoryPageCount,
        deviceAlternateIdEnabled, locationAlternateIdEnabled, currentCompanyLevel
    } = props;
    
    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    useEffect(() => {
        fetchCallHistoryList(1, 5);
    }, [props.company.toJS().id]);

    return (
        <div>
            <h2><Link id="recent-emergency-calls-header" to="/call-history">Recent Emergency Calls</Link></h2>
            <ReactTable
                resizable={false}
                sortable={false}
                className="-striped -highlight recent-emergency-calls-table"
                showPaginationBottom={false}
                data={callHistory ? callHistory.toArray() : []}
                minRows={5}
                pages={callHistoryPageCount}
                columns={buildTableColumns(currentCompanyLevel, deviceAlternateIdEnabled, locationAlternateIdEnabled)}
                loading = {loading}
                LoadingComponent={ReactTableLoadingComponent}
                defaultPageSize={5}
                manual
                onFetchData={(state, instance) => {
                    fetchCallHistoryList(state.page+1, state.pageSize);
                }}
                getTrProps={
                    (state, rowInfo, column) => {
                        if(rowInfo) {
                            return {
                                id: 'callHistory-row-' + rowInfo.index,
                            };
                        } else {
                          return {};
                        }
                    }
                }
            />
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    callHistoryPageCount: makeSelectCallHistoryPageCount(),
    callHistory: makeSelectCallHistoryList(),
    loading: makeSelectCallHistoryListLoading(),
    company: selectUserCompany(),
    deviceAlternateIdEnabled: selectAlternateIdEnabled(),
    locationAlternateIdEnabled: selectLocationAlternateIdEnabled(),
    currentCompanyLevel: currentCompanyLevel()
});

const mapDispatchToProps = dispatch => {
    return { 
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        fetchCallHistoryList: (page, pageSize) => dispatch(CallHistoryActions.getCallHistoryRequest(page, pageSize))
    }
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
    withConnect
)(CallHistorySection);